import { createGlobalStyle } from 'styled-components';
import regular from './fonts/geomanist-regular-webfont.woff';
import medium from './fonts/geomanist-medium-webfont.woff';
import book from './fonts/geomanist-book-webfont.woff';


const Typography = createGlobalStyle`
@font-face {
  font-family: regular;
  src: url(${regular});
}

@font-face {
    font-family: book;
    src: url(${book});
}
@font-face {
    font-family: medium;
    src: url(${medium});
}
  
p {
    font-family: 'regular';
}
b {
    font-family: 'medium';
    font-weight: normal;
}
h4 {
    font-family: 'book';
}
button {
    font-family: 'book';
    @media screen and (max-width: 1000px) {
        margin-bottom: 60px!important;
    }
}
@media screen and (max-width: 1000px) {
    .body-section { 
      flex-direction: column;
    }
}
.body-section {
    @media screen and (max-width: 375px) {
        max-width: 100%;  
        padding: 10px;  
    }
    @media screen and (min-width: 376px) {
    max-width: 80%;
    margin: 0 auto;
    max-width: 1340px;
}
.body-section-left, .body-section-right {
    border-radius: 5px 5px 0px 0px;
    padding: 8px 0px 0px 0px;
    color: #FFF;
    border-radius: 5px 5px 10px 10px;
    margin: 10px;
}
.body-section-left {
    background: #329678;
}
.body-section-right {
    background: #FAAA28;
}
label {
    padding-right: 60px;
}


.txt_left, .txt_right {
    float: left;
    padding: 10px 15px 10px 20px;
    @media screen and (max-width: 375px) {
        font-size: 0.8em;
    }
}

.result {
    margin-top: 30px;
}


.footer {
    background: #329678;
    a {
        color: #FFF;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}

.info-toggle {
    float: right;
    text-align: right;
    margin-right: 10px;
    margin-top: 8px;
}

.icon-info_green:before { content: '\e812'; }

`

export default Typography;