import React, { Component } from 'react';
import axios from 'axios';
import './App.css';

import styled from 'styled-components'
import GlobalStyle from './globalStyle'

import infoGreen from './info-icon-green.svg';
import infoOrange from './info-icon-orange.svg';


const endpoint = "https://api.amberscript.com/wer"
const BUTTON_SEND = "Start calculation";
const BUTTON_BUSY = "Please wait...";
const DESCRIPTION = "Calculate the word error rate of a text, by copy pasting the correct text to the Reference text box and the output of the ASR engine to the Hypothesis text box. The WER is calculated by comparing only the raw words, ignoring punctuation, line breaks and other formatting of the text using the NIST Scoring Toolkit (SCTK).";
const BOLD_TEXT_LABEL = "Place";
const REF_TEXT_LABEL = "Manual Transcription text in here";
const HYP_TEXT_LABEL = "Automatic Transcription text in here";


const Button = styled.button`
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 16px 22px;
  border: 1px solid transparent;
  border-radius: 2px;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-family: "Geomanist-Book";
  font-size: 18px;
  color: #FFF;
  -webkit-appearance: none;
  line-height: 1;
  width: 200px;
  text-align: center;
  cursor: pointer;
  background: #faaa28;
  font-family: 'book';
    &:hover {
        background: #005a50;
    }
  `
  const Textarea = styled.textarea`
    box-shadow: 0px 10px 10px rgba(0,0,0,0.1);
    border: 0;
    border: 1px solid #E4E4E4;
    &::placeholder {
      color: #DAD9DB;
    }
  `

  const Modal = styled.div`
  opacity: 0;
  visibility: hidden;
  display: block;
  background: #FFF;
  border-radius: 5px;
  margin: 0 auto;
  height: 0;
  padding: 0;
  box-shadow: 10px 10px 30px rgba(0,0,0,0.05);
  position: absolute;
    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 30px;
      height: 30px;
      display: inline-block;
      font-size: 20px;
      color: #000;
      cursor: pointer;
  }
  &.is-active {
      margin-top: 60px;
      height: auto;
      transform: translate(75%, 5%);
      width: 40%;
      opacity: 1;
      padding: 45px 5%;
      height: auto;
      visibility: visible;
      @media screen and (max-width: 1000px) {
          width: 60%;
          margin-left: -25%;
      }
  }

  `

class App extends Component {

  constructor(props) {
    super(props);

    this.updateTextRight = this.updateTextRight.bind(this);
    this.updateTextLeft = this.updateTextLeft.bind(this);
    this.send = this.send.bind(this);
  }

  state = {
    refText: '',
    hypText: '',
    werResult: '',
    utteranceMode: 'wsj',
    busy: false,
    toggleText: false
  }

  updateTextLeft = (e) => {
    this.setState({
      refText: e.target.value
    })
  }

  updateTextRight = (e) => {
    this.setState({
      hypText: e.target.value
    })
  }

  send = () => {
    if (!this.state.refText || !this.state.hypText) {
      alert('Please put in text in both text boxes');
      return;
    }
    this.setState({
      busy: true
    })
    axios.post(endpoint, {
      refText: this.state.refText,
      hypText: this.state.hypText,
      utteranceMode: this.state.utteranceMode,
    }).then(res => {
      this.setState({
        werResult: res.data.wer,
        busy: false
      })
    }).catch(err => {
      alert('error: ' + err);
      this.setState({
        busy: false
      })
    })
  }

  render() {
    return (
      <div className="App">
        <GlobalStyle />
      <Modal className={ this.state.toggleText === true ? 'is-active' : null }>
      <div className="close" onClick={() => this.setState({ toggleText: !this.state.toggleText })}>x</div>
        {DESCRIPTION}
      </Modal>

      <div style={{ minHeight: '70px', position: 'relative'}} >
        {this.state.werResult && <div className="result" style={{ position: 'absolute', top: '10px', width: '100%', textAlign: 'center'}}>
          <h4>Word Error Rate: {this.state.werResult}%</h4>
          {/* <pre className="result-text" style={{whiteSpace: 'pre-wrap'}}>
            {raw}
          </pre> */}
        </div>}
      </div>
   
        <div className="body-section">
          <div className="body-section-left">
            <label style={{float: 'left', padding: '7px', marginLeft: '15px'}} for="txt_left">
                <b>{BOLD_TEXT_LABEL}</b> &nbsp; 
                {REF_TEXT_LABEL}
            </label>

            <div className="info-toggle" style={{ transform: 'translate(-10px ,-4px)' }}  onClick={() => this.setState({ toggleText: !this.state.toggleText })}>
              <img src={infoGreen} alt="Info icon" />
            </div>

            <textarea className="form-control" id="txt_left" rows="10" onChange={e => this.updateTextLeft(e)}>
              {this.state.refText}
            </textarea>
          </div>
            <div className="body-section-right">
          <label style={{float: 'left', padding: '7px', marginLeft: '15px'}} for="txt_left">
                <b>{BOLD_TEXT_LABEL}</b> &nbsp; 
              {HYP_TEXT_LABEL}
            </label>

            <div className="info-toggle" style={{ transform: 'translate(-10px ,-4px)' }} onClick={() => this.setState({ toggleText: !this.state.toggleText })}>
                <img src={infoOrange} alt="Info icon" />
              </div>

            <textarea className="form-control" id="txt_right" rows="10" onChange={e => this.updateTextRight(e)}>
              {this.state.hypText}
            </textarea>
          </div>
        </div>
      


        <Button onClick={this.send} type="button" disabled={this.state.busy}>
                {this.state.busy ? BUTTON_BUSY : BUTTON_SEND}
        </Button>

        <div className="footer" style={{ background: '#329678'}}>
          Made by <a href="https://www.amberscript.com" style={{ color: '#FFF'}}>Amberscript</a>
        </div>
      </div>
    );
  }
}

export default App;
